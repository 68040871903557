import React, { Component } from 'react';
import { connect } from 'react-redux';
import Router from 'next/router';
import { parseCookies } from 'nookies';
import classNames from 'classnames';
import Link from 'next/link';
import { publish } from '@/utils/publish';
import queryString from 'query-string';
import getConfig from 'next/config';

import styles from './Login.module.scss';

import Logo from '@/components/Helpers/Logo';
import queryParams from '@/utils/queryParams';
import Notification from '@/components/Base/Notification';
import FormValidator from '@/components/Forms/Validator';
import Submit from '@/components/Forms/Submit';
import Links from '@/components/session/Links';
import Loader from '@/components/Helpers/Loader';
import { config } from '@/config';
import { setStateAsync } from '@/utils/helpers';

import { loginUser, loginSsoUser } from '@/store/actions/session.actions';

import { getSession } from '@auth0/nextjs-auth0';

const { publicRuntimeConfig } = getConfig();

class Login extends Component {
  static async getInitialProps({ ctx }) {
    const { token } = parseCookies(ctx);

    if (token) {
      if (ctx.res) {
        ctx.res.statusCode = 302;
        ctx.res.setHeader('Location', '/');
      } else {
        Router.push('/');
      }
    } else {
      try {
        if (config().SSO_FEATURE) {
          const { idToken } = (await getSession(ctx.req, ctx.res)) || {};

          return { sso_token: idToken, loading: !!idToken };
        } else {
          return {};
        }
      } catch (err) {
        return { err: err };
      }
    }
  }

  state = {
    formLogin: {
      login: '',
      password: '',
      remember: false,
      otp_secret: '',
      otp_attempt: ''
    },
    twoFactorAuthForm: {
      otp_secret: '',
      otp_attempt: '',
      save_this_device: false
    },
    passwordVisible: false,
    twoFactorRequired: false,
    otpAttemptVisible: false,
    type: '',
    loading: true
  };

  setStateAsync = setStateAsync.bind(this);

  async componentDidMount() {
    const login = queryParams('login');
    if (config().SSO_FEATURE) {
      if (this.props.sso_token) {
        await this.setStateAsync({ loading: this.props.loading });
        await this.props
          .loginSsoUser({ sso_token: this.props.sso_token })
          .then(val => {
            if (val && val.otp_secret && val.last_otp_at) {
              let otp_secret = val.otp_secret;
              let last_otp_at = val.last_otp_at;
              this.setState({
                twoFactorRequired: true,
                loading: false,
                formLogin: { otp_secret, otp_attempt: '', last_otp_at }
              });
            } else {
              this.setState({ loading: true, type: val.two_fa_type });
            }
          })
          .catch(res => {
            if (res.response?.status === 405) {
              const url = `/reset-password?${queryString.stringify(
                { login: this.state.formLogin.login },
                { arrayFormat: 'bracket' }
              )}`;
              Router.push('/reset-password', url).then(() => publish('error', res));
            }
            if (res.response?.status === 422) {
              publish('error', res);
              this.setState({ loading: false });
              window.location = `/api/auth/logout?returnTo=${publicRuntimeConfig.APP_URL}/sso-logout`;
            }
          });
      } else {
        this.setState({ loading: true });
        window.location = '/api/auth/login';
      }
    } else {
      this.setState({ loading: false });
    }
    if (login) {
      this.setState({ formLogin: { login } });
    }
    history.pushState(null, document.title, location.href);
    window.addEventListener('popstate', function () {
      history.pushState(null, document.title, location.href);
    });
  }

  get logInForm() {
    const {
      formLogin: { login, password, remember }
    } = this.state;

    if (config().SSO_FEATURE) {
      return null;
    }

    return (
      <form name="formLogin">
        <div className="field">
          <label className={classNames('label', styles.label)}>Username</label>
          <div className={classNames('control has-icons-right', styles.control)}>
            <input
              name="login"
              onChange={this.validateOnChange}
              className={classNames('input', {
                'is-danger': this.hasError('formLogin', 'login', 'required')
              })}
              type="text"
              placeholder="Enter username"
              data-validate='["required"]'
              value={login}
            />
            <span className={classNames('icon is-small is-right', styles.icon)}>
              <i className="fas fa-user" />
            </span>
          </div>
          {this.hasError('formLogin', 'login', 'required') && (
            <span className={classNames('help is-danger', styles.help)}>Field is required</span>
          )}
        </div>
        <div className="field">
          <label className={classNames('label', styles.label)}>Password</label>
          <div className={classNames('control has-icons-right', styles.control)}>
            <input
              name="password"
              className={classNames('input', {
                'is-danger': this.hasError('formLogin', 'password', 'required')
              })}
              type={this.state.passwordVisible ? 'Text' : 'Password'}
              placeholder="Password"
              onChange={this.validateOnChange}
              data-validate='["required"]'
              value={password}
            />
            <span className={classNames('icon is-small is-right', styles.passwordVisible, styles.icon)}>
              <i
                onClick={() => this.setState({ passwordVisible: !this.state.passwordVisible })}
                className={this.state.passwordVisible ? 'fas fa-eye' : 'fas fa-eye-slash'}
              />
            </span>
            {this.hasError('formLogin', 'password', 'required') && (
              <span className={classNames('help is-danger', styles.help)}>Field is required</span>
            )}
          </div>

          {/* <div className={styles.checkboxWrapper}>
            <div className="field">
              <input
                id="remember"
                type="checkbox"
                name="remember"
                className="is-checkradio is-medium"
                onChange={this.validateOnChange}
                checked={remember}
              />
              <label htmlFor="remember">Remember me</label>
            </div>
          </div> */}
          <Link href="/reset-password">
            <p className={classNames(styles.link, 'mt-2')}>Forgot your password?</p>
          </Link>
        </div>
        <Submit label="Login" className="button is-medium is-fullwidth is-success" onSubmit={this.onSubmit} />
      </form>
    );
  }

  labelText = () => {
    switch (this.state.type) {
      case 'application':
        return 'Please enter the code from the two-factor app on your device';
      case 'sms':
        return 'Please enter the code from the message on your mobile device';
      case 'email':
        return 'Please enter the code from your e-mail';
      default:
        return 'Please enter 2fa code';
    }
  };

  get twoFactorAuthForm() {
    const {
      formLogin: { login, password, otp_secret, otp_attempt, save_this_device }
    } = this.state;

    return (
      <form name="formLogin">
        <div className="field">
          <div className={classNames('control has-icons-right', styles.control)}>
            <input
              name="login"
              className={classNames('input')}
              type="hidden"
              invalid={`${this.hasError('formLogin', 'login', 'required')}`}
              data-validate='["required"]'
              value={login}
            />
          </div>
        </div>
        <div className="field">
          <label className={classNames('label', styles.label)}>{this.labelText()}</label>
          <div className={classNames('control has-icons-right', styles.control)}>
            <input
              name="otp_attempt"
              className={classNames('input', {
                'is-danger': this.hasError('formLogin', 'otp_attempt', 'required')
              })}
              type={this.state.otpAttemptVisible ? 'Text' : 'Password'}
              placeholder="Code"
              invalid={`${this.hasError('formLogin', 'otp_attempt', 'required')}`}
              onChange={this.validateOnChange}
              data-validate='["required"]'
              value={otp_attempt}
            />
            <span className={classNames('icon is-small is-right', styles.otpAttemptVisible, styles.icon)}>
              <i
                onClick={() => this.setState({ otpAttemptVisible: !this.state.otpAttemptVisible })}
                className={this.state.otpAttemptVisible ? 'fas fa-eye' : 'fas fa-eye-slash'}
              ></i>
            </span>
            {this.hasError('formLogin', 'otp_attempt', 'required') && (
              <span className={classNames('help is-danger', styles.help)}>Field is required</span>
            )}
          </div>
        </div>
        <div className={styles.checkboxWrapper}>
          <div className="field">
            <input
              id="save_this_device"
              type="checkbox"
              name="save_this_device"
              className="is-checkradio is-medium"
              onChange={this.validateOnChange}
              checked={save_this_device}
            />
            <label htmlFor="save_this_device">Save this device</label>
          </div>
        </div>
        <Submit label="Verify" className="button is-medium is-fullwidth is-success" onSubmit={this.onSubmit} />
      </form>
    );
  }

  validateOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === 'checkbox' ? input.checked : input.value;

    const result = FormValidator.validate(input);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value == input.checked ? value : value.split(/\s/).join(''),
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  onSubmit = async (e, callback) => {
    e.preventDefault();
    const form = e.target.form;
    const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName));
    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    });

    const { login, password, remember, otp_secret, otp_attempt, save_this_device } = this.state[form.name];

    if (this.props.sso_token) {
      await this.props
        .loginSsoUser({ sso_token: this.props.sso_token, otp_secret, otp_attempt, save_this_device })
        .then(val => {
          if (val && val.otp_secret && val.last_otp_at) {
            let otp_secret = val.otp_secret;
            this.setState({
              formLogin: { sso_token: this.props.sso_token, otp_secret, otp_attempt: '', save_this_device }
            });
            this.setState({ twoFactorRequired: true, loading: false });
          }
        })
        .catch(res => {
          if (res.response?.status === 405) {
            const url = `/reset-password?${queryString.stringify(
              { login: this.state.formLogin.login },
              { arrayFormat: 'bracket' }
            )}`;
            Router.push('/reset-password', url).then(() => publish('error', res));
          }
          publish('error', res);
          callback();
        });
    } else {
      if (hasError) {
        return callback();
      }
      await this.props
        .loginUser({ login, password, remember, otp_secret, otp_attempt, save_this_device })
        .then(val => {
          if (val && val.otp_secret && val.last_otp_at) {
            let otp_secret = val.otp_secret;
            this.setState({ formLogin: { login, password, remember, otp_secret, otp_attempt: '', save_this_device } });
            this.setState({ twoFactorRequired: true, type: val.two_fa_type });
          }
        })
        .catch(res => {
          if (res.response?.status === 405) {
            const url = `/reset-password?${queryString.stringify(
              { login: this.state.formLogin.login },
              { arrayFormat: 'bracket' }
            )}`;
            Router.push('/reset-password', url).then(() => publish('error', res));
          }
          publish('error', res);
          callback();
        });
    }
  };

  render() {
    if (this.state.loading) return <Loader loading />;
    return (
      <>
        <div className={styles.loginWrapper}>
          <div className={classNames('card', styles.card)}>
            {publicRuntimeConfig.APP_ENV !== 'demo' && (
              <div className={styles.imageWrapper}>
                <Logo />
              </div>
            )}
            {config().TWO_FACTOR_ENABLED && this.state.twoFactorRequired ? this.twoFactorAuthForm : this.logInForm}
            <Links />
          </div>
          {!config().REMOVED_COPYRIGHT_LINE && (
            <div className={styles.privacyInfo}>©{new Date().getFullYear()} - Market Access Direct</div>
          )}
        </div>
        <Notification classNames={'login'} />
      </>
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { loginUser, loginSsoUser })(Login);
